import * as React from 'react'
import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore,
} from '../components/styles'
import styled, { css } from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'

import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import Form from '../components/Form'
import FAQs from '../components/FAQs'

import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'

import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'
import YouTube from 'react-youtube'
import { ContactDetails } from './contact-us'

export const Video = styled(YouTube)`
  margin-top: 25px;
  width: 90vw;
  height: 50.75vw;
  max-height: 576px;
  max-width: 1024px;
  border-radius: 8px;
`

const Logos = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 900px;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  margin: -50px auto 0;
  z-index: 99;
  position: relative;

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    .gatsby-image-outer-wrapper:last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }

  .gatsby-image-outer-wrapper {
    width: 80px;
    height: auto;
  }
`

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

export const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: 15rem;
  }

  @media (max-width: 480px) {
    margin-top: 18rem;
  }

  section {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    figure {
      width: calc(100vw / 8);
    }
  }

  h2 {
    font-weight: 500;
    color: ${(props) => props.theme.colors.dark};
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;

    a {
      text-decoration: underline;
    }

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const HomeHeader = styled.header`
  height: 600px;
  padding-top: 9rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;
  min-height: 550px;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (min-width: 900px) and (max-width: 1280px) {
    figure {
      .gatsby-image-wrapper {
        overflow: visible !important;
        img {
          height: 650px !important;
        }
      }
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 500;
    opacity: 0.85;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
    figure,
    .gatsby-image-wrapper {
      height: 650px;
    }
  }
  @media (max-width: 600px) {
    > div:nth-child(2) {
      margin-top: 8rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    ${(props) =>
    props.slant &&
    css`
        transform: skewY(-6deg);
        transform-origin: left top 0px;
      `}
    overflow: hidden;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  > div {
    > h2,
    > p {
      text-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    max-width: 700px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 500;
    line-height: 150%;
    padding-right: 2rem;
    max-width: 500px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 11rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    padding-top: 12rem;
  }
`

export const FormContainer = styled.div`
  width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);

  > div {
    padding: 1.5rem 2rem !important;
    border-top: 0;
  }
  h2 {
    color: #333 !important;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`
export const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;

  > h2 {
    text-align: center;
    font-size: 3rem;
    padding: 0 8px;

    @media (max-width: 500px) {
      font-size: 2.5rem;
    }
    i {
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  background: #000;
  img {
    opacity: 0.3 !important;
  }
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const info = [
  {
    question: 'Custom designed for you',
    answer: `We understand that every lifestyle is unique. Your home, needs and situation are all factors that we consider with every new construction project. That's why we custom design your patio, for you, to create something you love.`,
  },
  {
    question: 'Complete project management',
    answer: `We all lead hectic lives, that's why we're not just builders. Here at Just Patios we'll take care of the entire project from start all the way through completion.`,
  },
  {
    question: 'Storm damage specialists',
    answer: `We're experts in storm damage and can work to assist you in a full range of services.`,
  },
  {
    question: 'Fully licenced and insured',
    answer: `Our builders are fully licenced and insured to guarantee the highest quality craftsmanship with every project.`,
  },
  {
    question: 'On schedule, on time',
    answer: `Our guarantee to you! We know how much you're looking forward to enjoying your new patio and so strive to always be on schedule and on time.`,
  },
]

export const AboutContact = (props) => <Contact {...props} />

/* prettier-ignore */
interface IProps {
  data: {
    womanWaterfall: {
      childImageSharp: {
        fluid: object
      }
    };
    woodsWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    waterBodyWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    faceCloseUp: {
      childImageSharp: {
        fluid: object
      }
    };
    drSellars: {
      childImageSharp: {
        fluid: object
      }
    };
    nonSurgical: {
      childImageSharp: {
        fluid: object
      }
    };
    asaps: {
      childImageSharp: {
        fluid: object
      }
    };
    asps: {
      childImageSharp: {
        fluid: object
      }
    };
    fracs: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => {
  return (
    <Layout header="light">
      <Helmet>
        <title>Patio Builders & Designers Brisbane | Just Patios</title>
        <meta
          name="description"
          content="Just Patios are the Sunshine Coast & Brisbane's leading patio, carport & enclosure builders. We also build carports, room enclosures & more. Call us today for your carport or patio building needs & we’ll provide a free design."
        />
        <meta
          name="google-site-verification"
          content="Ngjmc1Sk0AgniI0nPAd5pZQX8yvpaPUd5bTtc0gZc24"
        />
      </Helmet>

      <HomeHeader slant={true} style={{ height: 670 }}>
        <figure>
          <Img fluid={data.jpBackground.childImageSharp.fluid} />
        </figure>
        <div>
          <span>Customising Your Lifestyle</span>
          <h2>Patio Builders & Designers</h2>
          <Text size="large">
            Planning to give your home a makeover by building an outdoor patio?
            If you are, our team at Just Patios is ready to bring your design to
            life.
          </Text>
          <Link to="#designs">
            <Button>See some of our designs</Button>
          </Link>
        </div>
        <FormContainer>
          <div>
            <Form title="Request Free Quote" showMessage={true} />
          </div>
        </FormContainer>
      </HomeHeader>

      <VideoContainer>
        <h1>Patios in Brisbane & The Sunshine Coast</h1>
        <article>
          {/* <div style={{ border: '2px solid #F69F1A', borderRadius: '8px', marginBottom: 50, padding: '30px 40px' }}>
            <p>
              <i>
                <strong style={{ color: '#F69F1A' }}>
                  Just Patios office will be closing for the day on Friday the 5th of July as we are moving to our new shop location in Caboolture.
                </strong>
              </i>
              <br />
              <br />
              <i>
                <strong style={{ color: 'black' }}>
                  Rest assured we will be open for business as usual on Monday the 8th of July 2024.
                  We are excited to announce our new location in Caboolture very soon!
                </strong>
              </i>
            </p>
          </div> */}

          {/* <p>
            Just Patios is aware of the Nations health issues currently and
            respect both your health and our staff. We are still able to operate
            with the most stringent health and hygiene controls as advised by
            the Federal Government. This enables us to quote and build without
            concern to you the customer. Correspondence for quotes and contracts
            via phone, email , photos etc and a single onsite visit to confirm
            sizes (you don't have to be there). Builders are still able to
            construct your new project without anyone being home or use of your
            facilities apart from power/outdoor water. No access required in
            your home internally all can be done externally. Rest assured we are
            true professionals in the industry and with a healthy respect for
            your well being and our loyal and dedicated staff.
          </p> */}

          <p>
            <i>
              Welcome to Just Patios. Patio Builders for Brisbane and The
              Sunshine Coast
            </i>
          </p>
          <p>
            Planning to give your Brisbane home a makeover by building an
            outdoor patio? If you are, our team at{' '}
            <a
              href="https://www.justpatios.com.au/about-us/"
              title="Just Patios"
            >
              Just Patios
            </a>{' '}
            is ready to handle your request. We specialise in building patios
            and patio covers across Brisbane and the Sunshine Coast. We're based
            in the Brisbane Northside area and aim to deliver a high standard of
            workmanship with every project to keep you satisfied.
          </p>
          <p>
            Just Patios offer:{' '}
            <a
              href="https://www.justpatios.com.au/carport-builders-brisbane/"
              title="Carports Brisbane"
            >
              {' '}
              Car Ports;
            </a>
            <a
              href="https://www.justpatios.com.au/patio-builders-brisbane/"
              title="Patio Builders Brisbane"
            >
              {' '}
              Patio coverings;
            </a>
            <a
              href="https://www.justpatios.com.au/room-enclosures-brisbane/"
              title="Room Enclosures"
            >
              {' '}
              Room Enclosures; and
            </a>
            <a
              href="https://www.justpatios.com.au/patio-roofing-brisbane/"
              title="Roofing Contractors"
            >
              {' '}
              Roofing.
            </a>
          </p>
          <p>
            Whether you want insulated roofing for a relaxing outdoor space, or
            a protective structure for your car, you can count on Just Patios to
            assist and customise to your needs. If you want to do the
            installation, we provide DIY kits as well. We also install patio
            systems from leading patio manufacturers, including Ausdeck Patios
            and Solarspan insulated products.
          </p>
          <p>
            While we don’t believe in rushing projects, we finish our jobs
            promptly. Our professional, licensed builders make an impeccable
            team, so rest assured your new patio will be finished on schedule
            and according to your requirements.
          </p>
          <p>
            <i>
              Workmanship is our number one priority. Always on time, on budget,
              and customised for your lifestyle
            </i>
          </p>
          <p>
            What sets our team apart is our ability to add value to your
            property. Our high-quality workmanship enhances your whole home, not
            simply the part of your house that we're working on. Our dependable
            services always produce the highest quality results.
          </p>
          <p>
            Our builders are polite and friendly, ready to address your
            concerns. Our services are priced competitively and, should you have
            budget constraints, we can make adjustments to suit your finances.
          </p>
          <p>
            Here at Just Patios, we don’t just build; we customise. With
            services that are designed to meet your needs, we do more than just
            deliver high quality patios, roofs, coverings and other solutions;
            we provide products that fit your lifestyle. Learn more about our
            services by calling us today.
          </p>
        </article>
        <Video
          videoId="fwj5SkpIU6U"
          opts={{
            height: '576',
            width: '1024',
            playerVars: {
              color: 'white',
              rel: 0,
            },
          }}
        />
      </VideoContainer>

      <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
        <h3>Do you have questions?</h3>
        <p>
          Call 1300 284 531 or please complete the enquiry form to contact us
          today and find out how we can help.
        </p>
        <Flex>
          <Link to="/contact-us">
            <Button color={'#fff'} small={true} outline={true}>
              Contact Us
            </Button>
          </Link>
        </Flex>
      </Hero>

      <AlternatingContent id="designs">
        <ContentCard
          image={data.patiosBrisbane.childImageSharp}
          title="Patios"
          text="Just Patios are your reliable patio suppliers in Brisbane and the Sunshine Coast. With a variety of different ranges and designs we can help bring your patio to life."
          buttons={[
            { text: 'Patios', to: '/patio-builders-brisbane' },
            { text: 'Flyover', to: '/patio-builders-brisbane/flyover-patio' },
            {
              text: 'Skillion Non-Insulated',
              to: '/patio-builders-brisbane/skillion-non-insulated',
            },
            { text: 'Gable', to: '/patio-builders-brisbane/gable-patio' },
            {
              text: 'Skillion Insulated',
              to: '/patio-builders-brisbane/skillion-insulated',
            },
          ]}
        />
        <ContentCard
          image={data.training.childImageSharp}
          title="Carports"
          text="Our team is ready to help design, build, and customise your stunning new carport today! Protecting from harsh weather and damage, our carports are sure to keep your vehicle safe."
          buttons={[
            { text: 'Carports', to: '/carport-builders-brisbane' },
            {
              to: '/carport-builders-brisbane/raised-flyover-carport',
              text: 'Raised Flyover Carport',
            },
            {
              to: '/carport-builders-brisbane/skillion-carport',
              text: 'Flat / Skillion Carport',
            },
            {
              to: '/carport-builders-brisbane/gable-carport',
              text: 'Gable Carport',
            },
          ]}
        />
        <ContentCard
          image={data.offField.childImageSharp}
          title="Glass Room Enclosures"
          text="In need of more living area? With the help of our team we can assist you to increase the space, aesthetics, and function of your home. Enquire about our glass room enclosures today!"
          buttons={[
            { text: 'Glass Room Enclosures', to: '/room-enclosures-brisbane' },
          ]}
        />
        <ContentCard
          image={data.accessories.childImageSharp}
          title="Roofing"
          text="If you have any financing constraints, we can make adjustments in the design or materials to suit your budget. Our licensed builders’ premium workmanship enhances your whole property. "
          buttons={[
            { text: 'Ausdeck V-line', to: '/patio-roofing-brisbane' },
            { text: 'Insulated Roofing', to: '/patio-roofing-brisbane' },
            { text: 'Corrugated Iron Roofing', to: '/patio-roofing-brisbane' },
          ]}
        />
      </AlternatingContent>

      <ContactDetails
        title={
          <h2>
            Come And Visit Our <i>Showroom & Display</i> Area
          </h2>
        }
      >
        <Flex justifyContent="center" style={{ marginTop: 40 }}>
          <Link to="/contact-us/">
            <Button outline={true} small={true}>
              Get a free quote
            </Button>
          </Link>
        </Flex>
      </ContactDetails>

      <FAQs
        flushTop={true}
        faqs={info}
        title={
          <>
            What sets <i>us</i> apart?
          </>
        }
        background={data.whyUs.childImageSharp.fluid.src}
      />

      <Testimonials testimonials={allTestimonials} />

      <AboutContact data={data} />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    jpBackground: file(relativePath: { eq: "jp-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shirts: allFile(filter: { relativeDirectory: { eq: "home-shirts" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 700, toFormat: PNG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    logos: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    offField: file(relativePath: { eq: "room-enclosures-brisbane.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(relativePath: { eq: "patios-brisbane-new.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "carports-brisbane.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "about-gfit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessories: file(relativePath: { eq: "gallery/roof/iron.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90, toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "blurred-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
